/* @import url("https://fonts.googleapis.com/css2?family=Mukta&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: "Mukta", sans-serif; */
  font-family: "Caveat", cursive;
  color: var(--textColor);
}

body {
  background-color: #65b5f6;
}

.test {
  color: #e49e85;
  color: #fdca6b;
  color: #669fe9;
  color: #8acce2;
}

/* :oldroot {
  --maincolor: #2b592d;
  --maincolorLight: #5e9461;
  --secondaryColor: #97a07d;
  --textColor: #eee;
} */

:root {
  --maincolor: #e49e85;
  --maincolorLight: #fdca6b;
  --secondaryColor: #8acce2;
  --textColor: #eee;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  height: 80px;
  width: 100%;
  font-size: 24px;
  padding: 0 2rem;
  background-color: var(--maincolor);
  color: var(--textColor);
  z-index: 15;
}

.active {
  background-color: var(--maincolorLight);
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav .title {
  font-size: 2rem;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--textColor);
}

nav .title:hover {
  color: var(--secondaryColor);
  transition: 0.5s;
}

nav ul {
  display: flex;
  flex-direction: row;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  color: var(--textColor);
  text-decoration: none;
  padding: 0 0.7rem;
  margin: 0 0.5rem;
  border-radius: 5px;
}

nav li a:hover {
  color: var(--secondaryColor);
  transition: 0.5s;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

footer {
  display: block;
  text-align: center;
  /* padding-bottom: 10px; */
  margin-top: 100px;
  font-size: 1.2rem;
  color: var(--textColor);
  background-color: var(--maincolor);
}

.bio {
  padding-top: 100px;
}

.bio-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  font-size: 24px;
}



.bio-title {
  font-size: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.bio-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 2%;
  width: 50vw;
  border-radius: 15px;
}

.bio-img-main {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45vw;
  border-radius: 15px;
  padding-bottom: .12%;
}

.contact {
  padding-top: 100px;
}

.contact-title {
  font-size: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.contact-body {
    font-size: 32px;
    display: block;
    margin-left: auto;
    text-align: center;
    margin-right: auto;
    width: 50%;
  }

.contact-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border-radius: 15px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  margin: 10px 250px;
  font-size: 32px;
}

.itineraries {
  padding-top: 100px;
}

.itineraries-title {
  font-size: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.itineraries-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 5vw;
  padding-bottom: .25vw;
  font-size: 32px;
}

.itineraries-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 5vw;
  padding-bottom: 2vw;
  text-align: center;
  font-size: 24px;
}

.form-field {
  padding: 2px;
  background-color: var(--maincolorLight);
  color: var(--textColor);
  border-radius: 5px;
  width: 45vw;
  height: 5vh;
  border: none;
  font-size: 2.5vh;
  min-width: 10%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-main {
  height: 40vh;
}

.form-button {
  padding: 5px;
  background-color: var(--maincolor);
  color: var(--textColor);
  border-radius: 5px;
  width: 20vw;
  border: none;
  font-size: 4.5vh;
  min-width: 10%;
  margin-top: 10px;
  margin-bottom: 10px;
  

}

.form-button:hover {
  background-color: var(--) var(--secondaryColor);
  color: var(--secondaryColor);
  transition: 0.5s;
}

.socials {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: 20px;
}

.slider {
  padding-top: 150px;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 1000px;
  height: 600px;
  border-radius: 10px;
}

.text-on-image {
  position: absolute;
  right: 25vw;
  left: 25vw;
  top: 15%;
  text-align: center;
  color: var(--textColor);
  font-size: 32px;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: var(--textColor);
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: var(--textColor);
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  background-color: #65b5f6;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
  background-color: #65b5f6;
}

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15vw;
  padding-bottom: 5px;
  padding-top: 5px;
}

.testimonial-image {
  display: block;
  padding-top: 5vh;
  padding-bottom: 5vh;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border-radius: 15px;
}

.testimonial-author {
  font-size: 24px;
  color: var(--maincolorLight);
}

@media only screen and (max-width: 1024px) {
  * {
    max-width: 100vw;
  }

  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--maincolor);
    transition: 1s;
    transform: translateY(-100vh);
  }

  header nav ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  header .responsive_nav {
    transform: none;
  }

  .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .form-field {
    width: 60vw;
  }
  nav li {
    font-size: 1.5rem;
  }

  .slider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 50vh;
  }
  .slide.active {
    padding: 2vw;
  }

  .image {
    width: 60vw;
    max-height: 40vh;
  }
  .testimonial-image {
    width: 60vw;
  }
}
